.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .password {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        input,
        option,
        select {
            max-width: 400px;
            font-size: 1rem;
            padding: 0.8rem 0.5rem;
            background: rgb(236, 236, 236);
        }
    }
    button {
        align-self: flex-start;
        padding: 1rem;
        color: black;
        font-size: 1.1rem;
        background: var(--color-red-300);
        cursor: pointer;
        transition: all 300ms ease-in-out;
        border-radius: 8px;
        &:hover {
            background: hotpink;
            color: whitesmoke;
        }
    }
}
