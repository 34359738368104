
.filters {
    .filterContainer {
        display: inline-block;
        margin-right: 1.2rem;
        margin-bottom: 1.2rem;
        //outline: 1px salmon solid;
        border: 1px solid var(--color-inactive);
        padding: 0.3rem;
        border-radius: 10px;
    }

    .filter {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.3rem;
    }

    select {
        display: inline-block;
        //outline: 1px solid var(--color-red-300);
        font-size: 1rem;
        background: #ececec;
        color: black;
        width: 180px;
        padding: 0.6rem;
        padding-left: 0;
        option {
            padding: 1rem;
            background: #ececec;
        }
    }
}

.results {
    margin-top: 2rem;
    .btn {
        margin-bottom: 1rem;
        padding: 1rem;
        border-radius: 10px;
        color: #333;
        font-weight: 600;
        cursor: pointer;
        transition: all ease-in-out 200ms;
        &:hover {
            background: var(--color-active);
            color: whitesmoke;
        }
    }
    .inner {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}
