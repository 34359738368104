.container {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 768px) {
        display: none;
    }

    .logo {
        --font-size: 2rem;
        font-size: var(--font-size);
        font-weight: 500;
        margin-bottom: 2rem;
        a {
            color: var(--color-active);
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .mobileMenuLinks {
            display: none;
            @media screen and (max-width: 820px) {
                display: unset;
            }
        }
    }
}
