.iconContainer {
    display: flex;
    padding: 0.3rem;
    border-radius: 5px;
    svg {
        --svg-size: 1.8rem;
        width: var(--svg-size);
        height: var(--svg-size);
    }
}

.container {
    width: 100%;
    -webkit-box-shadow: -4px 6px 12px 3px rgba(0, 0, 0, 0.12);
    box-shadow: -4px 6px 12px 3px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    padding: 1.5rem;
    height: max-content;
    transition: height 200ms ease-in-out;
}

.inner {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 480px) {
        flex-direction: column;
        gap: 1rem;
    }

    .info {
        display: flex;
        align-items: flex-start;

        gap: 1rem;
        width: 100%;

        .categoryContainer {
            display: flex;
            flex-direction: column;
            width: 100%;

            .title {
                font-size: 1rem;
                font-weight: 400;
                &::first-letter {
                    text-transform: capitalize;
                }
            }

            .category {
                color: var(--color-active);
                font-size: 1.1rem;
                font-weight: 500;
                line-height: 1.2;
            }
            .date {
                font-size: 0.8rem;
                color: var(--color-inactive);
            }

            .description {
                height: 0;
                transition: all ease-in-out 0.2s;
                z-index: -1;
                p {
                    opacity: 0;
                }
            }

            .descriptionActive {
                margin-top: 1.2rem;
                height: auto;
                p {
                    transition: opacity ease-in-out 300ms;
                    transition-delay: 200ms;
                    opacity: 1;
                }
            }
        }
    }

    .moneyContainer {
        display: flex;
        align-items: center;
        gap: 0.2rem;

        @media screen and (max-width: 480px) {
            width: 100%;
            justify-content: space-between;
        }

        span {
            color: var(--color-active);
            font-size: 1.1rem;
            font-weight: 500;
        }
        .iconContainer {
            display: flex;
            color: var(--color-active);
            cursor: pointer;
            padding: 0.2rem;
            border-radius: 50%;
            transition: background 200ms ease-in-out;
            &:hover {
                background: lightgrey;
            }
            svg {
                --svg-size: 1.7rem;
                width: var(--svg-size);
                height: var(--svg-size);
            }
        }
    }
}
