.menu {
    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        width: 100%;
    }

    .inner {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 2rem;
    }

    --font-size: 1rem;
    .searchContainer {
        position: relative;
        display: flex;
        gap: 0.3rem;
        @media screen and (max-width: 768px) {
            max-width: 500px;
            width: 100%;
        }

        svg {
            color: var(--color-inactive);
            font-size: calc(var(--font-size) + 0.2rem);
        }

        input {
            color: var(--color-inactive);
            width: 300px;
            font-size: var(--font-size);
            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
        &:after {
            content: "";
            width: 100%;
            height: 0.5px;
            background: var(--color-inactive);
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .menuLink {
        transform: translateY(3px);
        a {
            font-size: var(--font-size);
            color: var(--color-inactive);
            transition: color ease-in-out 250ms;
            &:hover {
                color: var(--color-active);
            }
            @media screen and (max-width: 1050px) {
                display: none;
            }
        }
    }
}
